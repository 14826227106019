.textStart {
  text-align: start !important;
}
.textCenter {
  text-align: center !important;
}
.textLeft {
  text-align: left !important;
}
.textEnd {
  text-align: end !important;
}
.textCapitalize {
  text-transform: capitalize !important;
}
.textUppercase {
  text-transform: capitalize !important;
}
.textLowercase {
  text-transform: lowercase !important;
}

.lineHeight14 {
  line-height: 14px !important;
}
.lineHeight16 {
  line-height: 16px !important;
}
.lineHeight18 {
  line-height: 18px !important;
}
.lineHeight20 {
  line-height: 20px !important;
}
.lineHeight22 {
  line-height: 22px !important;
}
.lineHeight24 {
  line-height: 24px !important;
}
.lineHeight26 {
  line-height: 26px !important;
}
.lineHeight28 {
  line-height: 28px !important;
}
.lineHeight30 {
  line-height: 30px !important;
}
.lineHeight40 {
  line-height: 40px !important;
}
