// Padding Range------------>

.padding {
  padding: 0 50px;
}
.padding0 {
  padding: 0 !important;
}
.padding5 {
  padding: 5px !important;
}
.padding10 {
  padding: 10px !important;
}
.padding15 {
  padding: 15px !important;
}
.padding20 {
  padding: 20px !important;
}
.padding25 {
  padding: 25px !important;
}
.padding30 {
  padding: 30px !important;
}
.padding35 {
  padding: 35px !important;
}
.padding40 {
  padding: 40px !important;
}
.padding45 {
  padding: 45px !important;
}
.padding50 {
  padding: 50px !important;
}
.padding55 {
  padding: 55px !important;
}
.padding60 {
  padding: 60px !important;
}

// Padding Top Range------------>

.paddingTop0 {
  padding-top: 0px !important;
}
.paddingTop5 {
  padding-top: 5px !important;
}
.paddingTop10 {
  padding-top: 10px !important;
}
.paddingTop15 {
  padding-top: 15px !important;
}
.paddingTop20 {
  padding-top: 20px !important;
}
.paddingTop25 {
  padding-top: 25px !important;
}
.paddingTop30 {
  padding-top: 30px !important;
}
.paddingTop35 {
  padding-top: 35px !important;
}
.paddingTop40 {
  padding-top: 40px !important;
}
.paddingTop45 {
  padding-top: 45px !important;
}
.paddingTop50 {
  padding-top: 50px !important;
}
.paddingTop55 {
  padding-top: 55px !important;
}
.paddingTop60 {
  padding-top: 60px !important;
}

// Padding Bottom Range------------>

.paddingBottom0 {
  padding-bottom: 0px !important;
}
.paddingBottom5 {
  padding-bottom: 5px !important;
}
.paddingBottom10 {
  padding-bottom: 10px !important;
}
.paddingBottom15 {
  padding-bottom: 15px !important;
}
.paddingBottom20 {
  padding-bottom: 20px !important;
}
.paddingBottom25 {
  padding-bottom: 25px !important;
}
.paddingBottom30 {
  padding-bottom: 30px !important;
}
.paddingBottom35 {
  padding-bottom: 35px !important;
}
.paddingBottom40 {
  padding-bottom: 40px !important;
}
.paddingBottom45 {
  padding-bottom: 45px !important;
}
.paddingBottom50 {
  padding-bottom: 50px !important;
}
.paddingBottom55 {
  padding-bottom: 55px !important;
}
.paddingBottom60 {
  padding-bottom: 60px !important;
}

// Padding Left Range------------>

.paddingLeft0 {
  padding-left: 0px !important;
}
.paddingLeft5 {
  padding-left: 5px !important;
}
.paddingLeft10 {
  padding-left: 10px !important;
}
.paddingLeft15 {
  padding-left: 15px !important;
}
.paddingLeft20 {
  padding-left: 20px !important;
}
.paddingLeft25 {
  padding-left: 25px !important;
}
.paddingLeft30 {
  padding-left: 30px !important;
}
.paddingLeft35 {
  padding-left: 35px !important;
}
.paddingLeft40 {
  padding-left: 40px !important;
}
.paddingLeft45 {
  padding-left: 45px !important;
}
.paddingLeft50 {
  padding-left: 50px !important;
}
.paddingLeft55 {
  padding-left: 55px !important;
}
.paddingLeft60 {
  padding-left: 60px !important;
}

// Padding Right Range------------>

.paddingRight0 {
  padding-right: 0px !important;
}
.paddingRight5 {
  padding-right: 5px !important;
}
.paddingRight10 {
  padding-right: 10px !important;
}
.paddingRight15 {
  padding-right: 15px !important;
}
.paddingRight20 {
  padding-right: 20px !important;
}
.paddingRight25 {
  padding-right: 25px !important;
}
.paddingRight30 {
  padding-right: 30px !important;
}
.paddingRight35 {
  padding-right: 35px !important;
}
.paddingRight40 {
  padding-right: 40px !important;
}
.paddingRight45 {
  padding-right: 45px !important;
}
.paddingRight50 {
  padding-right: 50px !important;
}
.paddingRight55 {
  padding-right: 55px !important;
}
.paddingRight60 {
  padding-right: 60px !important;
}
