// Imgae Hover Effects Styling

.img_hover_effect {
  overflow: hidden !important;
  margin: 0 auto !important;
  width: 100% !important;
  transition: 0.5s all ease-in-out !important;
}

.img_hover_effect:hover {
  transform: scale(1.1) !important;
}

.hoverEffect:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
    0 5px 12px 4px rgb(0 0 0 / 9%);
}

// Card Hover Effect
.card-hover:hover {
  box-shadow: 0 0 4px $hover_color;
}

.cursorPointer {
  cursor: pointer;
}

.cursor:hover {
  cursor: pointer;
}

.boxShadowNone {
  box-shadow: none !important;
}
