/*  Checkboxes Global Styling */

.ant-checkbox-checked .ant-checkbox-inner {
  // border-color: var(--background-color) !important;
  // background-color: var(--background-color) !important;
  border-color: var(--background-color) !important;
  background-color: var(--background-color) !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: var(--background-color) !important;
}

.ant-checkbox-wrapper span:nth-of-type(even) {
  width: 100% !important;
}

.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
.ant-checkbox-checked:hover .ant-checkbox-inner {
  // border: 1px solid var(--background-color) !important;
  border-color: var(--background-color) !important;
  background-color: var(--background-color) !important;
}

:where(.css-dev-only-do-not-override-acm2ia).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-acm2ia).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: var(--background-color) !important;
}

.checkboxChecked {
  padding: 8px !important;
  border-radius: 6px !important;
  border: 1px solid var(--background-color) !important;
}

.checkboxBorder {
  padding: 8px !important;
  border-radius: 6px !important;
  border: 1px solid var(--background-color) !important;
}

.ant-checkbox-wrapper:hover {
  border-color: var(--background-color) !important;
}

// .ant-checkbox-wrapper {
//   span:nth-of-type(2) {
//     width: 100% !important;
//   }
// }

// .ant-checkbox-wrapper > span:nth-child(2) {
//   width: 100% !important;
// }
