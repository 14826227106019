// Positions CSS styles

.positionRelative {
  position: relative !important;
}

.positionAbsolute {
  position: absolute !important;
}

.positionFixed {
  position: fixed !important;
}

.positionSticky {
  position: sticky !important;
}

.Top0 {
  top: 0 !important;
}

.Right0 {
  right: 0 !important;
}

.Left0 {
  left: 0 !important;
}

.Bottom0 {
  bottom: 0 !important;
}
.Top50{
  top: 50px;
}
.overflowy-auto {
  overflow-y: auto !important;
}