// Width Range------------>

.width0 {
  width: 0 !important;
}
.width5 {
  width: 5 !important;
}
.width10 {
  width: 10% !important;
}
.width15 {
  width: 15% !important;
}
.width20 {
  width: 20% !important;
}
.width25 {
  width: 25% !important;
}
.width30 {
  width: 30% !important;
}
.width35 {
  width: 35% !important;
}
.width40 {
  width: 40% !important;
}
.width45 {
  width: 45% !important;
}
.width50 {
  width: 50% !important;
}
.width60 {
  width: 60% !important;
}
.width70 {
  width: 70% !important;
}
.width80 {
  width: 80% !important;
}
.width90 {
  width: 90% !important;
}
.width98{
  width: 98% !important;
}
.width100 {
  width: 100% !important;
}
.minContent {
  width: min-content !important;
}
.maxContent {
  width: max-content !important;
}
