// Display Properties

.flex {
  display: flex !important;
}

.flexStart {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.flexCenter {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.flexEnd {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.flexBetween {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.flexBetweenStart {
  display: flex !important;
  align-items: start !important;
  justify-content: space-between !important;
}

.flexAround {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.flexEvenly {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
}

.flexColumn {
  display: flex !important;
  flex-direction: column !important;
}

.flexRow {
  display: flex !important;
  flex-direction: row !important;
}

.alignStart {
  display: flex !important;
  align-items: flex-start !important;
}

.alignCenter {
  display: flex !important;
  align-items: center !important;
}

.alignEnd {
  display: flex !important;
  align-items: flex-end !important;
}

.displayNone {
  display: none !important;
}

.displayBlock {
  display: block !important;
}

.displayInLineBlock {
  display: inline-block !important;
}

// Flex Grow Range ------------------->

.flexGrow1 {
  flex-grow: 1 !important;
}

.flexWrap {
  display: flex !important;
  flex-wrap: wrap !important;
}

// Flex Basis Range ------------------->

.basis0 {
  flex-basis: 0% !important;
}
.basis5 {
  flex-basis: 5% !important;
}
.basis10 {
  flex-basis: 10% !important;
}
.basis15 {
  flex-basis: 15% !important;
}
.basis20 {
  flex-basis: 20% !important;
}
.basis25 {
  flex-basis: 25% !important;
}
.basis30 {
  flex-basis: 30% !important;
}
.basis33 {
  flex-basis: 33% !important;
}
.basis35 {
  flex-basis: 35% !important;
}
.basis40 {
  flex-basis: 40% !important;
}
.basis45 {
  flex-basis: 45% !important;
}
.basis50 {
  flex-basis: 50% !important;
}
.basis55 {
  flex-basis: 55% !important;
}
.basis60 {
  flex-basis: 60% !important;
}
.basis65 {
  flex-basis: 65% !important;
}
.basis70 {
  flex-basis: 70% !important;
}
.basis75 {
  flex-basis: 75% !important;
}
.basis80 {
  flex-basis: 80% !important;
}
.basis85 {
  flex-basis: 85% !important;
}
.basis90 {
  flex-basis: 90% !important;
}
.basis95 {
  flex-basis: 95% !important;
}
.basis100 {
  flex-basis: 100% !important;
}

// Flex Gap Range ------------------->

.gap0 {
  gap: 0px !important;
}
.gap5 {
  gap: 5px !important;
}
.gap10 {
  gap: 10px !important;
}
.gap15 {
  gap: 15px !important;
}
.gap20 {
  gap: 20px !important;
}
.gap25 {
  gap: 25px !important;
}
.gap30 {
  gap: 30px !important;
}
.gap33 {
  gap: 33px !important;
}
.gap35 {
  gap: 35px !important;
}
.gap40 {
  gap: 40px !important;
}
.gap45 {
  gap: 45px !important;
}
.gap50 {
  gap: 50px !important;
}

.grid {
  display: grid !important;
  // grid-template-columns: auto !important;
}
