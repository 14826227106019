#notfound {
  position: relative;
  height: 100vh;
}

.notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
}

.notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 140px;
  height: 140px;
  background-image: url(../assets/images/Emojis/error_emoji.webp);
  background-size: cover;
}

.notfound-404:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(2.4);
  -ms-transform: scale(2.4);
  transform: scale(2.4);
  border-radius: 50%;
  background-color: #fff3f7;
  z-index: -1;
}

.notfound h1 {
  //   font-family: nunito, sans-serif;
  font-size: 65px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
  color: #151723;
  text-transform: uppercase;
}

.notfound h2 {
  //   font-family: nunito, sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  color: #151723;
}

.notfound p {
  //   font-family: nunito, sans-serif;
  color: #999fa5;
  font-weight: 400;
}

.notfound a {
  //   font-family: nunito, sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;
  text-decoration: none;
  color: #388dbc;
}
