// Global Styles

@import "./Width.scss";
@import "./Errors.scss";
@import "./Colors.scss";
@import "./Border.scss";
@import "./Margin.scss";
@import "./Effects.scss";
@import "./Display.scss";
@import "./Padding.scss";
@import "./Buttons.scss";
@import "./Positions.scss";
@import "./CheckBoxes.scss";
@import "./Typography.scss";
@import "../assets/Fonts/Fonts.scss";

html,
body {
  margin: 0;
  padding: 0;
  // font-family: "Poppins", sans-serif !important;
  font-family: "ZemestroStd-Regular" !important;
}

a {
  color: $black !important;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  // font-family: "Poppins" !important;
  font-family: "ZemestroStd-Light";
}

.disableScroll {
  overflow: hidden !important;
}

.ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Home Page Global Styling */
// .homepage-container {
//   background: $background;
// }
.staticPages {
  margin: 130px auto 100px auto !important;
}

.ant-layout {
  background: none !important;

  .ant-layout-header {
    background: none;
    height: auto;
    line-height: 25px;

    span {
      color: $black;
    }
  }

  .ant-layout-content {
    min-height: 80vh;
  }

  .ant-layout-footer {
    background: none;
  }
}

/*  Carousel Global Styling */
body .ant-modal-content {
  opacity: 1 !important;
  border-radius: 20px !important;
}

.carousel_container {
  // min-width: 80vw;
  margin: auto !important;
  width: 100vw !important;
  max-width: 100% !important;
  // .ant-card-body {
  //   width: 50% !important;
  // }

  // .slick-slide {
  //   img {
  //     // width: 300px !important;
  //   }
  // }
}

.single-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
}

// .slick-track {
//   width: 100% !important;
// }
.ant-typography {
  table {
    width: 100% !important;
  }
}
.carousel_container .evenCard {
  margin-top: 14px;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

/*  Content Selection Global Styling */
.theme-bg-color {
  background: var(--background-color) !important;
}
.theme-text-color {
  color: var(--text-color) !important;
}
// .ant-checkbox-checked .ant-checkbox-inner {
//   background-color: var(--background-color) !important;
// }
::selection {
  color: $surface !important;
  background: var(--background-color) !important;
}

/*  Categories Tabs Global Styling */

.ant-tabs-ink-bar {
  background: $primaryOne !important;
}

.ant-tabs-tab-btn {
  color: $black !important;
}

.row {
  overflow: hidden !important;
  width: 100% !important;
}

.backgroundColor {
  background: $white !important;
}

.backgroundLight {
  background: $surface !important;
}

.backgroundDark {
  background: $background !important;
}

.ant-table-row-selected {
  td {
    background: none !important;
  }
}

// removing background hover effect on menu item in dropdown
.ant-dropdown-menu-item {
  background: none !important;
}

// // Scrollbar Styling ----------------->

// // Width of Scrollbar

// ::-webkit-scrollbar {
//   display: none !important;
//   // width: 5px !important;
// }

// // Track of Scrollbar
// ::-webkit-scrollbar-track {
//   // background: red;
// }

// // Handle of Scrollbar

// ::-webkit-scrollbar-thumb {
//   border-radius: 10px !important;
//   background: $soft_peach !important;
// }

// // Scrollbar Hover

// ::-webkit-scrollbar-thumb:hover {
//   // background: black;
// }

// // End of Scrollbar Styling ----------------->

.cursorPointer {
  cursor: pointer;
}

.hoverEffect:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
    0 5px 12px 4px rgb(0 0 0 / 9%);
}

// home page sections title styling

.sectionHeadingContainer {
  //padding: 10px 0px;

  // .sectionHeadingBold {
  //   font-family: 'ZemestroStd-Medium' !important;
  //   margin: 10px !important;
  //   font-size: 25px !important;
  //   text-align: center;
  //   color: $secondaryTwo !important;
  // }

  // .sectionHeadingMobileBold {
  //   font-family: 'ZemestroStd-Medium' !important;
  //   margin: 10px !important;
  //   font-size: 16px !important;
  //   text-align: center;
  //   color: $secondaryTwo !important;
  // }
  // .sectionHeadingSemiBold {
  //   font-family: "ZemestroStd-SemiBold" !important;
  //   margin: 10px !important;
  //   font-size: 24px !important;
  //   text-align: center;
  //   color: $secondaryTwo !important;
  // }
}

// .Banner {
//   a > div {
//     display: block !important;
//     width: 100% !important;
//   }
// }

.nextImage {
  position: relative;
  width: 100%;
}

// setting all ant cards border-radius 0
.ant-card {
  .ant-card-cover {
    img {
      border-radius: 0px !important;
    }
  }
}
body .ant-modal .ant-modal-close-x {
  background-color: var(--background-color) !important;
}
.ant-modal-close-x {
  background-color: var(--background-color) !important;
  svg {
    font-size: 16px !important;
    color: #929292 !important;
  }
}

.NotifyModal {
  .ant-modal {
    width: 533px !important;
    height: 434px !important;

    .ant-modal-content {
      padding: 10px !important;
      border-radius: 0px !important;

      .ant-modal-close-icon {
      }

      .ant-modal-header {
        .ant-modal-title {
          font-size: 20px;
          color: #1e1e1e;
          text-align: center !important;
        }
      }

      .ant-modal-body {
        padding: 10px 40px !important;

        .notify_form {
          input {
            height: 41px !important;
            width: 100% !important;
            border-radius: 5px !important;
            border: 1px solid #e0e0e0 !important;
          }

          .react-tel-input {
            .form-control,
            input {
              height: 41px !important;
              width: 100% !important;
              border-radius: 5px !important;
              border: 1px solid #e0e0e0 !important;
            }

            .flag-dropdown {
              border-radius: 5px !important;
              border: 1px solid #e0e0e0 !important;
            }
          }
        }
      }

      .ant-modal-footer {
      }
    }
  }
}

.slick-track::before,
.slick-track::after {
  display: none !important;
}

// Mz Css
// Global Scroll design

body .ant-drawer .ant-drawer-content {
  overflow-y: auto;
}

// .global-loader {
//   position: fixed;
//   min-height: 100vh;
//   width: 100%;
//   top: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #fff;
//   // background-color: #e5e5e5 !important;
//   z-index: 99;
// }
body .TrengoWidgetLauncher__iframe .trengo-vue-iframe {
  border-radius: 50%;
}

// Mz Css

.thankyou-page {
  margin-top: 130px;
}

@media (max-width: 1024px) {
  body #trengo-web-widget .TrengoWidgetLauncher__iframe {
    display: none;
  }

  #launcher-frame {
    display: none;
  }

  .thankyou-page {
    margin-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 1024px) {
  .staticPages {
    margin: 100px auto !important;
  }
}

// body #freshworks-container {
//   display: none !important;
// }

.mobile-bottom-nav > div:nth-child(3) {
  background: var(--background-color) !important;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.mobile-bottom-nav > div:nth-child(3) .nav-link {
  background: var(--background-color) !important;
  border-radius: 50%;
}

.mobile-bottom-nav > div:nth-child(3) .nav-link img {
  width: 35px;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body .login-modal-outer-container {
  .ant-modal-content {
    border-radius: 35px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
html {
  background-image: url("../assets/images/finalbackground.jpg");
  width: 100%;
  background-size: contain;
}
body .slick-arrow.slick-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
body .ordered-list {
  ol {
    padding-left: 16px !important;
  }
  p {
    font-size: 14px !important;
    text-align: left;
    line-height: 20px;
  }
}
body .ant-select-single .ant-select-show-arrow:hover {
  border-color: var(--background-color) !important;
}
body
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: var(--background-color) !important;
}
body .ant-select-focused {
  border-color: var(--background-color) !important;
  box-shadow: var(--background-color) !important;
}
body .ant-select-open {
  border-color: var(--background-color) !important;
}
body .ant-wave-target {
  border-color: var(--background-color) !important;
}
body .ant-select-focused .ant-select-selector,
body .ant-select-selector:hover,
body
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector {
  // box-shadow: 0 0 0 2px var(--background-color) !important;
  box-shadow: none !important;
}
